import "../component/src/ui/modal/styles.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../component/src/ui/modal/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA51UwY6bMBC95yuslSptDo6AzaYqUU+V+gs9VFU14AHcNR5kG0K26r9XNpAQkl01vSVv/Dzz3jyz+Rm3L/2u7p/Y7xVjDVnpJOmUFbJHsV8xJrVFl7LI/84gfykNtVrwnBSZlHVgHjmHopAa+axckwA1HFp75iuXWmC/IIzocHq9X/1ZbaZ5tv8yj5C2UXBMWaGw9wAoWWouHdY2ZTlqh8bDv1rrZHHkOWmH2s1Ldw/2HAbjfPofpWx/AcRLIBmAgxSumvqc2MGdCmVZuWUtDrVaan67noR6Rj238lXqMmUZGYGGZxTMKEg7X8KFuIBnYHF9OnUYG2yj4Kvyx6am8WbnsQaECE2SqOlZsm1Cj/OCDCpwssP7ckIdGgVH3oDGeV6CqgoEHRbchhrPGYvj2aDagJCtTVmcDKPV0J805KDyxziKuopx9pQ0fSBS67zSlGnSeLHk3SJ9kFlSrQviHDUpS8YmZrjfe/JfafoYGr27qYrvrhe1u7mo7fMg3JRS84yco3ryY9bzU+h59XauHokvcNTinYc1hoIHT7bRZHzoHzBoHXmshGZyaT7JJljylcih+SZd9aWSShjUg/3zy29wDXZoLA4O+lmFNJiPaaQDH+tva7MOjLu4FMJlcwHnNF2YOi385H0UfQiR6tAUig78eNZ+ejkR2y5EZLdXsVSTk2pr7ZnfBTgYgvT5oQBl8eHH1TdT+vVw7FA7ew73LSpbfNaW3EHCbOD8voHHxY8R/AtL3xeIbQYAAA==\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var closeButton = '_1ukx6mx6';
export var confirmModalContainer = '_1ukx6mxb';
export var confirmModalContent = '_1ukx6mxa';
export var heightVar = 'var(--_1ukx6mx1)';
export var minHeightVar = 'var(--_1ukx6mx2)';
export var modalContent = '_1ukx6mx5';
export var modalContentWrapper = '_1ukx6mx4';
export var modalDescription = '_1ukx6mx8';
export var modalFooter = '_1ukx6mx9';
export var modalHeader = '_1ukx6mx7';
export var modalOverlay = '_1ukx6mx3';
export var promptModalContent = '_1ukx6mxc';
export var widthVar = 'var(--_1ukx6mx0)';